<template>
  <b-container>
    <div
      class="d-flex justify-content-between detail-box p-4 align-items-center"
    >
      <p class="name">{{ packageData.test_name }}</p>
      <div class="d-flex align-items-center">
        <del v-if="packageData.mrp !== null">{{ packageData.price }}</del>
        <p class="ml-3 price" v-if="packageData.mrp !== null">
          {{ packageData.mrp }}
        </p>
        <p class="ml-3 price" v-else>{{ packageData.price }}</p>
      </div>
    </div>
    <div class="info-box p-3 mt-3">
      <div class="d-flex justify-content-between align-items-center pr-2 pl-2">
        <p class="top">Package Detail</p>
        <router-link
          class="book-btn d-flex text-center px-5 py-2"
          :to="{
            name: `package_book`,
            params: { profile: $route.params.profile },
          }"
        >
          <p class="button-text">Book Now</p>
        </router-link>
      </div>
      <div class="description mt-2 d-flezx text-center">
        <p>No Details available</p>
        <!-- <p>
          The importance of good health in one's life cannot be overstated.
          However, preventive habits such as eating healthily, going for a walk,
          exercising, swimming, sports, avoiding junk food, and stress relievers
          such as yoga and music are just not enough to sustain good health.
        </p>
        <p>
          The reflection of an individual's lifestyle and management on the life
          is referred to as a comprehensive health checkup. The Healthians 100%
          Good Health Package in Gurgaon includes a thorough body examination.
        </p>
        <p>
          Timely screening can help you in avoiding illnesses and with
          Healthians Healthians 100% Good Health Package you can keep a track of
          your health in the best way because Healthians is India's leading
          health test @ home service with more than 20 lac satisfied customers
          across 140+ cities. The Healthians 100% Good Health Package available
          in Gurgaon has 105 parameters that give you a clear idea of your
          health. You can choose Healthians 100% Good Health Package available
          in Gurgaon or other packages better suited for you to keep your health
          in check.
        </p> -->
      </div>
      <!-- <b-col class="d-flex align-items-center rules" md="3">
        <b-icon
          icon="person-fill"
          font-scale="1.5"
          style="color: #86acfa"
        ></b-icon>
        <p class="b-para ml-2">Age Group: 12-19 Yrs</p>
      </b-col>
      <b-col class="d-flex align-items-center rules-2 mt-2" md="6">
        <b-icon
          icon="exclamation-circle-fill"
          font-scale="1.5"
          style="color: #ff0000"
        ></b-icon>
        <p class="b-para ml-2">
          Do not eat and drink anything 12 hours before the test
        </p>
      </b-col> -->
    </div>
    <template
      v-if="$options.components['advertisement-healthians-middle-banner']"
    >
      <advertisement-healthians-middle-banner />
    </template>
    <template
      v-if="$options.components['advertisement-healthians-bottom-banner']"
    >
      <advertisement-healthians-bottom-banner />
    </template>
  </b-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      packageList: (state) => state.healthCare.specificPackageList,
    }),
  },
  data() {
    return {
      packageData: {},
    };
  },
  methods: {
    fetchPackageData() {
      this.packageData = this.packageList.find(
        (el) => el.deal_id == this.$route.params.profile
      );
    },
  },
  mounted() {
    this.fetchPackageData();
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped>
.detail-box {
  background-color: #17dce8;
  color: #fff;
  border-radius: 10px;
}
.name {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
}
.price {
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 600;
}
.book-btn {
  background-color: #0038ab;
  border-radius: 6px;
  text-decoration: none;
}
.button-text {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #fff;
  margin: 0px;
}
.top {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
}
.info-box {
  background-color: #f3f7fb;
  border-radius: 10px;
}
.description {
  font-size: 16px;
}
.b-para {
  margin-bottom: 0px;
  font-weight: 500;
}
.rules {
  border-radius: 7px;
  background-color: #dde8ff;
  display: inline-block;
}
.rules-2 {
  border-radius: 7px;
  background-color: #f4e6e9;
}
</style>

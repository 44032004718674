var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('div', {
    staticClass: "d-flex justify-content-between detail-box p-4 align-items-center"
  }, [_c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.packageData.test_name))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.packageData.mrp !== null ? _c('del', [_vm._v(_vm._s(_vm.packageData.price))]) : _vm._e(), _vm.packageData.mrp !== null ? _c('p', {
    staticClass: "ml-3 price"
  }, [_vm._v(" " + _vm._s(_vm.packageData.mrp) + " ")]) : _c('p', {
    staticClass: "ml-3 price"
  }, [_vm._v(_vm._s(_vm.packageData.price))])])]), _c('div', {
    staticClass: "info-box p-3 mt-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center pr-2 pl-2"
  }, [_c('p', {
    staticClass: "top"
  }, [_vm._v("Package Detail")]), _c('router-link', {
    staticClass: "book-btn d-flex text-center px-5 py-2",
    attrs: {
      "to": {
        name: "package_book",
        params: {
          profile: _vm.$route.params.profile
        }
      }
    }
  }, [_c('p', {
    staticClass: "button-text"
  }, [_vm._v("Book Now")])])], 1), _c('div', {
    staticClass: "description mt-2 d-flezx text-center"
  }, [_c('p', [_vm._v("No Details available")])])]), _vm.$options.components['advertisement-healthians-middle-banner'] ? [_c('advertisement-healthians-middle-banner')] : _vm._e(), _vm.$options.components['advertisement-healthians-bottom-banner'] ? [_c('advertisement-healthians-bottom-banner')] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }